import {
	ComDivalCommerceRestQuoteHandlerQuoteHandlerConvertQuoteResponse,
	ComDivalCommerceRestQuoteHandlerQuoteHandlerFindByOrderIdExternalResponse,
	ComDivalCommerceRestQuoteHandlerQuoteHandlerFindByStatusExternalResponse,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class Quote<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;
	traceDetails: string | undefined;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
		this.traceDetails = process.env.TRACE_DETAILS?.trim();
	}

	/**
	 * @description Get Quote History Lists.
	 *
	 * @tags Quote
	 * @name FindByStatusExternal
	 * @summary Get Quote History Lists.
	 * @request GET:/store/{storeId}/quote/byStatusExternal/{status}
	 * @secure
	 * @response `200` `ComDivalCommerceRestQuoteHandlerQuoteHandlerFindByStatusExternalResponse` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	findByStatusExternal = (
		storeId: string,
		status: string,
		query?: {
			/** The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json. */
			responseFormat?: 'xml' | 'json';
			/** Page number, starting at 1. Valid values include positive integers of 1 and above. The "pageSize" must be specified for paging to work. */
			pageNumber?: number;
			/** Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The "pageNumber" must be specified for paging to work. */
			pageSize?: number;
			/** The backendOrder identifier. */
			backendOrderId?: string;
			/** The PO number identifier. */
			poNumber?: string;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('findByStatusExternal'))
		) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'findByStatusExternal',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			ComDivalCommerceRestQuoteHandlerQuoteHandlerFindByStatusExternalResponse,
			void
		>({
			path: `/store/${storeId}/quote/byStatusExternal/${status}`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
	/**
	 * @description Get Quote Details.
	 *
	 * @tags Quote
	 * @name FindByOrderIdExternal
	 * @summary Get Quote Details.
	 * @request GET:/store/{storeId}/quote/byOrderIdExternal/{backendOrderId}
	 * @secure
	 * @response `200` `ComDivalCommerceRestQuoteHandlerQuoteHandlerFindByOrderIdExternalResponse` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	findByOrderIdExternal = (
		storeId: string,
		backendOrderId: string,
		query?: {
			/** The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json. */
			responseFormat?: 'xml' | 'json';
			/** Page number, starting at 1. Valid values include positive integers of 1 and above. The "pageSize" must be specified for paging to work. */
			pageNumber?: number;
			/** Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The "pageNumber" must be specified for paging to work. */
			pageSize?: number;
			/** The userId to run this request on behalf of. */
			forUserId?: number;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('findByOrderIdExternal'))
		) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'findByOrderIdExternal',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			ComDivalCommerceRestQuoteHandlerQuoteHandlerFindByOrderIdExternalResponse,
			void
		>({
			path: `/store/${storeId}/quote/byOrderIdExternal/${backendOrderId}`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
	/**
	 * @description Convert Quote to Orders.
	 *
	 * @tags Quote
	 * @name ConvertQuote
	 * @summary Convert Quote to Orders.
	 * @request POST:/store/{storeId}/quote/convertQuote/{backendOrderId}
	 * @secure
	 * @response `200` `ComDivalCommerceRestQuoteHandlerQuoteHandlerConvertQuoteResponse` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	convertQuote = (
		storeId: string,
		backendOrderId: string,
		query?: {
			/** The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json. */
			responseFormat?: 'xml' | 'json';
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (loggerCan('trace') && (!this.traceDetails || this.traceDetails.includes('convertQuote'))) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'convertQuote',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			ComDivalCommerceRestQuoteHandlerQuoteHandlerConvertQuoteResponse,
			void
		>({
			path: `/store/${storeId}/quote/convertQuote/${backendOrderId}`,
			method: 'POST',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
}
