import { ComDivalCommerceRestExternalcontractHandlerGetContractPriceByPartNumberResponse } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class ExternalPrice<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;
	traceDetails: string | undefined;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
		this.traceDetails = process.env.TRACE_DETAILS?.trim();
	}

	/**
	 * No description
	 *
	 * @tags External Price
	 * @name GetContractPriceByPartNumber
	 * @summary Finds external contract price for catalog entries by catentryIds, using the A+ customer number.
	 * @request GET:/store/{storeId}/externalcontract/price/byPartNumbers
	 * @secure
	 * @response `200` `ComDivalCommerceRestExternalcontractHandlerGetContractPriceByPartNumberResponse` The requested completed successfully.
	 * @response `400` `void` Bad request. Some of the inputs provided to the request aren't valid.
	 * @response `401` `void` Not authenticated. The user session isn't valid.
	 * @response `403` `void` The user isn't authorized to perform the specified request.
	 * @response `404` `void` The specified resource couldn't be found.
	 * @response `500` `void` Internal server error. Additional details will be contained on the server logs.
	 */
	getContractPriceByPartNumber = (
		storeId: string,
		query: {
			/** The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json. */
			responseFormat?: 'xml' | 'json';
			/** The list of partNumbers. */
			partNumbers: string[];
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('getContractPriceByPartNumber'))
		) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'getContractPriceByPartNumber',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			ComDivalCommerceRestExternalcontractHandlerGetContractPriceByPartNumberResponse,
			void
		>({
			path: `/store/${storeId}/externalcontract/price/byPartNumbers`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
}
