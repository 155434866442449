import {
	ComDivalCommerceRestOrderHandlerCollectCarrierHandlerCreate,
	ComDivalCommerceRestOrderHandlerCollectCarrierHandlerCreateResponse,
	ComDivalCommerceRestOrderHandlerCollectCarrierHandlerFindByMemberIdResponse,
	ComDivalCommerceRestOrderHandlerCollectCarrierHandlerGetByIdResponse,
	ComDivalCommerceRestOrderHandlerCollectCarrierHandlerSetForOrder,
	ComDivalCommerceRestOrderHandlerCollectCarrierHandlerSetForOrderResponse,
	ComDivalCommerceRestOrderHandlerCollectCarrierHandlerUpdateStatus,
	ComDivalCommerceRestOrderHandlerCollectCarrierHandlerUpdateStatusResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class CollectCarriers<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;
	traceDetails: string | undefined;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
		this.traceDetails = process.env.TRACE_DETAILS?.trim();
	}

	/**
	 * No description
	 *
	 * @tags Collect Carriers
	 * @name GetById
	 * @summary Finds shipping method informtion by its Collect Carrier ID.
	 * @request GET:/store/{storeId}/collectCarrier/{collectCarrierId}
	 * @secure
	 * @response `200` `ComDivalCommerceRestOrderHandlerCollectCarrierHandlerGetByIdResponse` The requested completed successfully.
	 * @response `400` `void` Bad request. Some of the inputs provided to the request aren't valid.
	 * @response `401` `void` Not authenticated. The user session isn't valid.
	 * @response `403` `void` The user isn't authorized to perform the specified request.
	 * @response `404` `void` The specified resource couldn't be found.
	 * @response `500` `void` Internal server error. Additional details will be contained on the server logs.
	 */
	getById = (
		storeId: string,
		collectCarrierId?: string,
		query?: {
			/** The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json. */
			responseFormat?: 'xml' | 'json';
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (loggerCan('trace') && (!this.traceDetails || this.traceDetails.includes('getById'))) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'getById',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			ComDivalCommerceRestOrderHandlerCollectCarrierHandlerGetByIdResponse,
			void
		>({
			path: `/store/${storeId}/collectCarrier/${collectCarrierId}`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
	/**
	 * No description
	 *
	 * @tags Collect Carriers
	 * @name FindByMemberId
	 * @summary Finds Collect Carrier List by Member ID.
	 * @request GET:/store/{storeId}/collectCarrier/memberId/{memberId}
	 * @secure
	 * @response `200` `ComDivalCommerceRestOrderHandlerCollectCarrierHandlerFindByMemberIdResponse` The requested completed successfully.
	 * @response `400` `void` Bad request. Some of the inputs provided to the request aren't valid.
	 * @response `401` `void` Not authenticated. The user session isn't valid.
	 * @response `403` `void` The user isn't authorized to perform the specified request.
	 * @response `404` `void` The specified resource couldn't be found.
	 * @response `500` `void` Internal server error. Additional details will be contained on the server logs.
	 */
	findByMemberId = (
		storeId: string,
		memberId?: string,
		query?: {
			/** The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json. */
			responseFormat?: 'xml' | 'json';
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('findByMemberId'))
		) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'findByMemberId',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			ComDivalCommerceRestOrderHandlerCollectCarrierHandlerFindByMemberIdResponse,
			void
		>({
			path: `/store/${storeId}/collectCarrier/memberId/${memberId}`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
	/**
	 * No description
	 *
	 * @tags Collect Carriers
	 * @name Create
	 * @summary To create Collect Carrier in DB.
	 * @request PUT:/store/{storeId}/collectCarrier
	 * @secure
	 * @response `200` `ComDivalCommerceRestOrderHandlerCollectCarrierHandlerCreateResponse` The requested completed successfully.
	 * @response `400` `void` Bad request. Some of the inputs provided to the request aren't valid.
	 * @response `401` `void` Not authenticated. The user session isn't valid.
	 * @response `403` `void` The user isn't authorized to perform the specified request.
	 * @response `404` `void` The specified resource couldn't be found.
	 * @response `500` `void` Internal server error. Additional details will be contained on the server logs.
	 */
	create = (
		storeId: string,
		data: ComDivalCommerceRestOrderHandlerCollectCarrierHandlerCreate,
		query?: {
			/** The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json. */
			responseFormat?: 'xml' | 'json';
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (loggerCan('trace') && (!this.traceDetails || this.traceDetails.includes('create'))) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: data ?? {},
				methodName: 'create',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			ComDivalCommerceRestOrderHandlerCollectCarrierHandlerCreateResponse,
			void
		>({
			path: `/store/${storeId}/collectCarrier`,
			method: 'PUT',
			query: query,
			body: data,
			secure: true,
			type: params.type ?? ContentType.Json,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
	/**
	 * No description
	 *
	 * @tags Collect Carriers
	 * @name UpdateStatus
	 * @summary To update the status of a Collect Carrier ID in DB.
	 * @request POST:/store/{storeId}/collectCarrier/updateStatus
	 * @secure
	 * @response `200` `ComDivalCommerceRestOrderHandlerCollectCarrierHandlerUpdateStatusResponse` The requested completed successfully.
	 * @response `400` `void` Bad request. Some of the inputs provided to the request aren't valid.
	 * @response `401` `void` Not authenticated. The user session isn't valid.
	 * @response `403` `void` The user isn't authorized to perform the specified request.
	 * @response `404` `void` The specified resource couldn't be found.
	 * @response `500` `void` Internal server error. Additional details will be contained on the server logs.
	 */
	updateStatus = (
		storeId: string,
		data: ComDivalCommerceRestOrderHandlerCollectCarrierHandlerUpdateStatus,
		query?: {
			/** The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json. */
			responseFormat?: 'xml' | 'json';
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (loggerCan('trace') && (!this.traceDetails || this.traceDetails.includes('updateStatus'))) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: data ?? {},
				methodName: 'updateStatus',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			ComDivalCommerceRestOrderHandlerCollectCarrierHandlerUpdateStatusResponse,
			void
		>({
			path: `/store/${storeId}/collectCarrier/updateStatus`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: params.type ?? ContentType.Json,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
	/**
	 * No description
	 *
	 * @tags Collect Carriers
	 * @name SetForOrder
	 * @summary To set Collect Carrier Info for an Order.
	 * @request POST:/store/{storeId}/collectCarrier/setForOrder
	 * @secure
	 * @response `200` `ComDivalCommerceRestOrderHandlerCollectCarrierHandlerSetForOrderResponse` The requested completed successfully.
	 * @response `400` `void` Bad request. Some of the inputs provided to the request aren't valid.
	 * @response `401` `void` Not authenticated. The user session isn't valid.
	 * @response `403` `void` The user isn't authorized to perform the specified request.
	 * @response `404` `void` The specified resource couldn't be found.
	 * @response `500` `void` Internal server error. Additional details will be contained on the server logs.
	 */
	setForOrder = (
		storeId: string,
		query: {
			/** The language Identifier. */
			langId: string;
			/** The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json. */
			responseFormat?: 'xml' | 'json';
		},
		data: ComDivalCommerceRestOrderHandlerCollectCarrierHandlerSetForOrder,
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (loggerCan('trace') && (!this.traceDetails || this.traceDetails.includes('setForOrder'))) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: data ?? {},
				methodName: 'setForOrder',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			ComDivalCommerceRestOrderHandlerCollectCarrierHandlerSetForOrderResponse,
			void
		>({
			path: `/store/${storeId}/collectCarrier/setForOrder`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: params.type ?? ContentType.Json,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
}
