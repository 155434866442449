import {
	ComDivalCommerceRestOrderHandlerOrderHistoryHandlerFindByOrderIdExternalResponse,
	ComDivalCommerceRestOrderHandlerOrderHistoryHandlerFindByStatusExternalResponse,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class OrderHistory<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;
	traceDetails: string | undefined;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
		this.traceDetails = process.env.TRACE_DETAILS?.trim();
	}

	/**
	 * @description Finds Order History List by External Order Status.
	 *
	 * @tags Order History
	 * @name FindByStatusExternal
	 * @summary Find Order History List
	 * @request GET:/store/{storeId}/orderHistory/byStatusExternal/{status}
	 * @secure
	 * @response `200` `ComDivalCommerceRestOrderHandlerOrderHistoryHandlerFindByStatusExternalResponse` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	findByStatusExternal = (
		storeId: string,
		status: string,
		query?: {
			/** The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json. */
			responseFormat?: 'xml' | 'json';
			/** Page number, starting at 1. Valid values include positive integers of 1 and above. The "pageSize" must be specified for paging to work. */
			pageNumber?: number;
			/** Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The "pageNumber" must be specified for paging to work. */
			pageSize?: number;
			/** The order identifier. */
			orderId?: string;
			/** The PO number identifier. */
			poNumber?: string;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('findByStatusExternal'))
		) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'findByStatusExternal',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			ComDivalCommerceRestOrderHandlerOrderHistoryHandlerFindByStatusExternalResponse,
			void
		>({
			path: `/store/${storeId}/orderHistory/byStatusExternal/${status}`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
	/**
	 * @description Finds Order History Details by External Order ID.
	 *
	 * @tags Order History
	 * @name FindByOrderIdExternal
	 * @summary Find Order History Details
	 * @request GET:/store/{storeId}/orderHistory/byOrderIdExternal/{orderId}
	 * @secure
	 * @response `200` `ComDivalCommerceRestOrderHandlerOrderHistoryHandlerFindByOrderIdExternalResponse` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	findByOrderIdExternal = (
		storeId: string,
		orderId: string,
		query?: {
			/** The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json. */
			responseFormat?: 'xml' | 'json';
			/** Page number, starting at 1. Valid values include positive integers of 1 and above. The "pageSize" must be specified for paging to work. */
			pageNumber?: number;
			/** Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The "pageNumber" must be specified for paging to work. */
			pageSize?: number;
			/** The userId to run this request on behalf of. */
			forUserId?: number;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('findByOrderIdExternal'))
		) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'findByOrderIdExternal',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			ComDivalCommerceRestOrderHandlerOrderHistoryHandlerFindByOrderIdExternalResponse,
			void
		>({
			path: `/store/${storeId}/orderHistory/byOrderIdExternal/${orderId}`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			...params,
		});
	};
}
