
import { HttpClient } from './http-client';
import { CatalogEntryExternal } from './CatalogEntryExternal';
import { CatentryRef } from './CatentryRef';
import { CollectCarriers } from './CollectCarriers';
import { ContactUs } from './ContactUs';
import { CreditCard } from './CreditCard';
import { CustomCart } from './CustomCart';
import { ExternalInventory } from './ExternalInventory';
import { ExternalPrice } from './ExternalPrice';
import { OrderExtData } from './OrderExtData';
import { OrderHistory } from './OrderHistory';
import { Quote } from './Quote';
import { SplitRateShop } from './SplitRateShop';
const publicClient = new HttpClient({
	baseUrl: process.env.NODE_ENV === 'production' ? '/wcs/resources':'/api/resources',
	isPublic: true
});
const privateClient = new HttpClient({
	baseUrl: (process.env.USE_MOCK === 'true' ? 'http://localhost:' + process.env.MOCK_HOST_PORT : process.env.TRANSACTION_ORIGIN as string) + '/wcs/resources',
});

export const customCatalogEntryExternal = (pub: boolean) => new CatalogEntryExternal(pub ? publicClient : privateClient);
export const customCatentryRef = (pub: boolean) => new CatentryRef(pub ? publicClient : privateClient);
export const customCollectCarriers = (pub: boolean) => new CollectCarriers(pub ? publicClient : privateClient);
export const customContactUs = (pub: boolean) => new ContactUs(pub ? publicClient : privateClient);
export const customCreditCard = (pub: boolean) => new CreditCard(pub ? publicClient : privateClient);
export const customCustomCart = (pub: boolean) => new CustomCart(pub ? publicClient : privateClient);
export const customExternalInventory = (pub: boolean) => new ExternalInventory(pub ? publicClient : privateClient);
export const customExternalPrice = (pub: boolean) => new ExternalPrice(pub ? publicClient : privateClient);
export const customOrderExtData = (pub: boolean) => new OrderExtData(pub ? publicClient : privateClient);
export const customOrderHistory = (pub: boolean) => new OrderHistory(pub ? publicClient : privateClient);
export const customQuote = (pub: boolean) => new Quote(pub ? publicClient : privateClient);
export const customSplitRateShop = (pub: boolean) => new SplitRateShop(pub ? publicClient : privateClient);
